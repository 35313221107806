<template>
  <v-overlay
    :absolute="absolutePosition"
    :value="loading"
    :z-index="zIndex"
  >
    <v-progress-circular
      :size="size"
      :style="`max-height: ${maxHeight}`"
      indeterminate
    />
  </v-overlay>
</template>

<script>
export default {
  name: 'PagePreLoader',
  props: {

    loading: {
      type: Boolean,
    },

    absolutePosition: {
      type: Boolean,
      default: true
    },

    zIndex: {
      type: [Number, String],
      default: 1
    },

    size: {
      type: [Number, String],
      default: 64
    },

    maxHeight: {
      type: String,
      default: '100vh'
    }
  }
};
</script>

<style scoped>

</style>
